
/*
=======================================
    Table of Content
=======================================
    - Colours
        if any colour changed, apply the changes to tokens inside theme.js file too.
        inside theme.js file, colour shades have been generated using the below colours and "tailwind shades" extension, 
            by applying ctrl+k ctrl+g
    - Fonts
    - Sizes
    - Boostrap Overwrite
*/

/*=-=-=-=-=-=
    Colours
=-=-=-=-=-=*/
$app_text: #131421;
$app_action: #e8404b;
$app_accent: #2ebbd8;
$app_dark_bg: #023759;
$app_light_bg: #ffffff;

/*=-=-=-=-=-=
    Fonts
=-=-=-=-=-=*/
@font-face {
    font-family: "soleil-regular";
    src: url("../../fonts/soleil/SoleilRegular.otf");
}
@font-face {
    font-family: "soleil-bold";
    src: url("../../fonts/soleil/SoleilBold.otf");
}
@font-face {
    font-family: "soleil-light";
    src: url("../../fonts/soleil/SoleilLight.otf");
}
$app_font_main_reg: 'soleil-regular';
$app_font_main_bold: 'soleil-bold';
$app_font_main_light: 'soleil-light';
/* -=-=-=-=- */
@font-face {
    font-family: "museo-regular";
    src: url("../../fonts/museo-sans-rounded/MuseoSansRounded500.otf");
}
@font-face {
    font-family: "museo-bold";
    src: url("../../fonts/museo-sans-rounded/MuseoSansRounded900.otf");
}
@font-face {
    font-family: "museo-light";
    src: url("../../fonts/museo-sans-rounded/MuseoSansRounded100.otf");
}
$app_font_alt_reg: 'museo-regular';
$app_font_alt_bold: 'museo-bold';
$app_font_alt_light: 'museo-light';

/*=-=-=-=-=-=
    Sizes
=-=-=-=-=-=*/
$app_sidebar_full: '250px';
$app_sidebar_collapsed: '80px';
/*=-=-=-=-=-=
--- Boostrap Overwrite
=-=-=-=-=-=*/
$headings-font-family: $app_font_main_reg;
$grid-gutter-width: 0.5rem;