/*
=======================================
    Table of Content
=======================================
    - Layout
    - Sidebar 
        -- react-pro-sidebar
    - Fonts
    - Sizes
*/
/*=-=-=-=-=-=
    Layout
=-=-=-=-=-=*/
.content {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    align-items: flex-start;
}
/*=-=-=-=-=-=
    Sidebar
        -- including theme handling
=-=-=-=-=-=*/
.dark .ps-sidebar-container {
    background-color: darken($color: $app_dark_bg, $amount: 1);
}
.light .ps-sidebar-container {
    background-color: darken($color: $app_light_bg, $amount: 1);
}

