@tailwind base;
@tailwind components;
@tailwind utilities;

/*
=======================================
    Table of Content
=======================================
    1- Colour Setting
    2- Custom Font
    3- Loading Progress Bar
    4- Input type Date Customization
    5- Custom Classes
    6- Machine Slider
    7- Map Container
    8- Transaction list -- Column selection style
*/

/*=-=-=-=-=-=
  1. Colour Setting
=-=-=-=-=-=*/
:root {
  .dark {
    --color-text: #ffffff;
    --color-text-100: #f5f5f5;
    --color-text-200: #f8f8ff;
    --color-text-300: #faf9f9;
    --color-text-400: #f8f9fa;
    --color-bg: #023759;
    --color-bg-100: #011f33;
    --color-bg-200: #022b46;
    --color-bg-300: #003455;
    --color-bg-400: #023e64;
    --color-bg-600: #035b93;
    --color-bg-700: #174c6e;
  }
  .light {
    --color-text: #131421;
    --color-text-100: #303253;
    --color-text-200: #292b46;
    --color-text-300: #21233a;
    --color-text-400: #1a1c2d;
    --color-bg: #f5f5f5;
    --color-bg-100: #ffffff;
    --color-bg-200: #f8f8ff;
    --color-bg-300: #e3e3e3;
    --color-bg-400: #eaeeef;
    --color-bg-600: #e0e0e0;
    --color-bg-700: #e0e0e0;
  }
  .dark.development {
    --color-bg: #444f5b;
    --color-bg-100: #171e25;
    --color-bg-200:rgb(31, 40, 52);
    --color-bg-300: #4e5863;
    --color-bg-400:rgb(57, 70, 86);
    --color-bg-600: #4e5863;
    --color-bg-700: #2d3846;
  }
  .light.development {
    --color-bg: #ecf4ff;
    --color-bg-100: #ffffff;
    --color-bg-200: #eff1f5;
    --color-bg-300: #e3e3e3;
    --color-bg-400:rgb(235, 241, 244);
    --color-bg-600: #bad6dc;
    --color-bg-700: #e8f2f9;
  }
  --color-action: #e8404b;
  --color-accent: #2ebbd8;

  --color-success: #28a413;
  --color-warning: #FFBF00;
}
/*=-=-=-=-=-=
  2. Custom Font
=-=-=-=-=-=*/
@font-face {
  font-family: "soleil-regular";
  src: url("assets/fonts/soleil/SoleilRegular.otf");
}
@font-face {
  font-family: "soleil-bold";
  src: url("assets/fonts/soleil/SoleilBold.otf");
}
@font-face {
  font-family: "soleil-light";
  src: url("assets/fonts/soleil/SoleilLight.otf");
}
@layer base {
  html {
    font-family: 'soleil-regular';
  }
  .font-bold {
    font-family: 'soleil-bold';
  }
  .font-light {
    font-family: 'soleil-light';
  }
}
/*=-=-=-=-=-=
  3. Loading Progress Bar
=-=-=-=-=-=*/
.progress {
  animation: progress 1s infinite linear;
}
.left-right {
    transform-origin: 0% 50%;
}
@keyframes progress {
  0% {
      transform:  translateX(0) scaleX(0);
  }
  40% {
      transform:  translateX(0) scaleX(0.4);
  }
  100% {
      transform:  translateX(100%) scaleX(0.5);
  }
}

/*=-=-=-=-=-=
  4. Input type Date Customization
=-=-=-=-=-=*/
[type="date"] {
  background: var(--color-bg-400) url('') 97% 50% no-repeat;
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 36px;
  width: auto;
}
/*=-=-=-=-=-=
  5. Custom Classes
=-=-=-=-=-=*/
@layer components {
  .btn-primary {
    @apply 
      rounded-md 
      bg-[--color-accent] 
      px-3 
      py-2 
      text-nowrap
      text-sm 
      font-semibold 
      leading-6 
      text-[#21233a] 
      hover:text-white 
      shadow-sm 
      hover:bg-[--color-action]
      focus:transition-all
      transition-all
      duration-500
      disabled:bg-[--color-bg-200] 
      disabled:border-[--color-bg-300] 
      disabled:shadow-inner 
      disabled:text-slate-500;
  }
  .btn-secondary {
    @apply 
      rounded-md 
      bg-[--color-bg-200] 
      px-3 
      py-2 
      text-nowrap
      text-sm 
      font-semibold 
      leading-6 
      shadow-sm 
      hover:bg-[--color-action]
      hover:text-white
      focus:transition-all
      duration-300
      disabled:bg-[--color-bg-200] 
      disabled:border-[--color-bg-300] 
      disabled:shadow-inner 
      disabled:text-slate-500;
  }
  .custom-input {
    @apply 
      rounded-md 
      border-0 
      py-2
      px-2
      min-h-[40px]
      h-[40px]
      text-[--color-text-400] 
      bg-[--color-bg-400]
      ring-1 
      ring-[--color-bg-300]
      placeholder:text-gray-400 
      outline-none
      ring-inset
      transition-all
      duration-300
      focus:ring-1  
      focus:ring-[--color-accent] 
      sm:text-sm 
      sm:leading-6
      disabled:shadow-inner
      disabled:shadow-[--color-bg-700]
      disabled:opacity-50
      disabled:cursor-not-allowed
      invalid:border-[--color-action] 
      invalid:text-[--color-action]
      focus:invalid:border-[--color-action] 
      focus:invalid:ring-[--color-action];
  }
  .form-group .custom-input {
    @apply
      rounded-e-none;
  }
  .form-group .attached-button {
    @apply
      bg-[--color-bg-400]
      px-2
      rounded
      rounded-s-none;
  }
  .card {
    @apply 
      border-2
      border-[--color-bg-300] 
      rounded-md 
      shadow-md 
      md:p-12 
      p-3;
    }
  .page-card {
    @apply
      border
      border-[--color-bg-600] 
      border-solid
      rounded-md 
      /* shadow-md  */
      p-5
      min-h-96;
  }
  /* .dark .sticky-col
  .light .sticky-col */
  .dark .page-card {
    @apply
      bg-[--color-bg-100];
      hover:bg-current;
  }
  .light .page-card {
    @apply
      bg-[--color-bg-100];
      hover:bg-current;
  }
  .custom-label {
    @apply 
      block 
      text-sm 
      font-medium 
      leading-9 
      text-[--color-text-200];
  }
  .text-link {
    @apply 
      text-sm 
      font-medium 
      text-[--color-accent]
      hover:text-[--color-action]
      focus:transition-all
      transition-all
      duration-300;
  }
  .transition {
    @apply
      transition-all 
      duration-300;
  }
  .main-content {
    height: -moz-available;          /* WebKit-based browsers will ignore this. */
    height: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    height: fill-available;
  }
  .dark .table-responsive,
  .dark .main-content,
  .dark .sidebar-scrollable,
  .dark .custom-input,
  .dark .scrollbar {
    @apply
      scrollbar-thumb-custom-scrollbarDarkThumb 
      scrollbar-track-custom-scrollbarDarkTrack;
  }
  .light .table-responsive,
  .light .main-content,
  .light .sidebar-scrollable,
  .light .custom-input,
  .light .scrollbar {
    @apply
      scrollbar-thumb-custom-scrollbarLightThumb 
      scrollbar-track-custom-scrollbarLightTrack;
  }
  .mobile-sidebar {
    @apply
      fixed
      w-[300px]
      translate-x-[-300px];
  }
  .mobile-sidebar.open {
    @apply
      translate-x-[0];
  }
  .inactive .sort-button {
    @apply
      invisible
      transition;
  }
  .inactive.sortable-column:hover .sort-button,
  .active.sortable-column .sort-button {
    @apply
      visible
      transition;
  }
}

.react-multi-carousel-track {
  flex-direction: column!important;
  width: 100%;
}

@media (max-width: 767px) {
  .table-responsive {
    max-height: 100vh!important;
  }
}
/*=-=-=-=-=-=
  6. Machine Slider
=-=-=-=-=-=*/

.carousel-column {
  display: flex;
  flex-direction: column;
}

.carousel-item {
  padding: 10px;
  text-align: center;
  border: 1px solid #ccc;  /* Optional: for better visualization */
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*=-=-=-=-=-=
  7. Map Container
=-=-=-=-=-=*/
.leaflet-container {
  height: 60vh;
  border-radius: 0.375rem;
}
.leaflet-grab:not(:focus) {cursor: pointer!important;}
/*=-=-=-=-=-=
  8. Transaction list -- Column selection style
=-=-=-=-=-=*/
.translactionList #visibleColumn_form .custom-label {
  display: flex;
  position: relative;
}
.translactionList #visibleColumn_form .custom-label div.truncate {
  position: absolute;
  left: 5px;
  top: 2px;
  max-width: 80px;
  z-index: 1;
}
.translactionList #visibleColumn_form .custom-label {
  display: flex;
}
.translactionList #visibleColumn_form .custom-label .css-11w3bay,
.translactionList #visibleColumn_form .custom-label .css-1v1tc4e {
  padding-left: 80px;
}